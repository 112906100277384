
import { arrayOf, bool, nullable, oneOfType, shape, string } from 'vue-types';
import { COLOR_SCHEME_DARK } from '~/constants/General';

export const ItemType = shape({
  title: oneOfType([string(), nullable()]),
  body: oneOfType([string(), nullable()]),
  introduction: oneOfType([string(), nullable()]),
  url: oneOfType([string(), nullable()]),
  greenhouseId: oneOfType([string(), nullable()]),
  category: arrayOf(
    shape({
      id: oneOfType([string(), nullable()]),
      title: oneOfType([string(), nullable()]),
      colorName: oneOfType([string(), nullable()]),
    }).loose
  ),
  location: arrayOf(
    shape({
      id: string(),
      title: string(),
    }).loose
  ),
  jobType: oneOfType([string(), nullable()]),
  hours: oneOfType([string(), nullable()]),
  openApplication: bool().def(false),
}).loose;

export default {
  inheritAttrs: false,
  props: {
    data: ItemType.def({}),
  },
  data() {
    return {
      COLOR_SCHEME_DARK,
    };
  },
  computed: {
    strippedBody() {
      const body = this.data.introduction || this.data.body || '';
      return body.replace(/<\/?[^>]+(>|$)/g, '');
    },
  },
  methods: {
    openApplyModal() {
      this.$store.commit('overlays/apply', {
        active: true,
        careerTitle: this.data.title,
        greenhouseId: this.data.greenhouseId,
      });
    },
  },
};
