
import { arrayOf, bool, oneOf, oneOfType, shape, string } from 'vue-types';
const Filter = shape({
  id: string(),
  title: string(),
}).loose;

export const FilterCategory = shape({
  id: string(),
  title: string(),
  filters: arrayOf(Filter),
}).loose;

export default {
  inheritAttrs: false,
  props: {
    categories: arrayOf(FilterCategory),
    value: shape({
      type: arrayOf(string()),
      location: arrayOf(string()),
      techType: arrayOf(string()),
    }),
    // whether to render the categories horizontally or vertically
    layout: oneOf(['vertical', 'horizontal']).def('vertical'),
    // show only the specified category (by id). used in CarerList modal for the mobile/secondary filters
    only: oneOfType([string(), bool()]),
  },
  computed: {
    classes() {
      return [this.layout === 'horizontal' ? 'flex gap-x-32' : 'space-y-32'];
    },
  },
  methods: {
    onInputChange(value, category, filter) {
      const filters = { ...this.value };

      if (value) {
        filters[category.id].push(filter.id);
      } else {
        filters[category.id] = filters[category.id].filter(
          (id) => id !== filter.id
        );
      }

      this.$emit('input', filters);
    },
  },
};
