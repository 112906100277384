
import { bool } from 'vue-types';
import query from '@/gql/queries/components/career-list.gql';
import { COLOR_SCHEME_WHITE } from '~/constants/General';

/**
 * Callback function for filtering items on type category (e.g. 'Technology')
 * Note that 'this' here refers to the 'filters' data property of the component.
 */
function filterByType(item) {
  if (!item || !item.category?.length) {
    return false;
  }

  if (!this?.length) {
    return true;
  }

  return this.includes(item.category[0].id);
}

function filterByLocation(item) {
  if (!item || !item.location?.length) {
    return false;
  }

  if (!this?.length) {
    return true;
  }

  return this.includes(item.location[0].id);
}

function filterByTechType(item) {
  if (!item || !item.jobType) {
    return false;
  }

  if (!this?.length) {
    return true;
  }

  return this.includes(item.jobType);
}

const DEFAULT_FILTERS = {
  type: [],
  location: [],
  techType: [],
};

export default {
  props: {
    active: bool().def(false),
  },
  data() {
    return {
      loading: false,
      careerCategoriesFetched: false,
      careerCategories: [],
      filters: DEFAULT_FILTERS,
      showFilters: false,
      COLOR_SCHEME_WHITE,
    };
  },
  computed: {
    careers() {
      return this.$store.state.openings || [];
    },
    openApplication() {
      return this.$store.state.openApplications[0];
    },
    categories() {
      const types = this.careerCategories.map((category) => {
        let occurrences = 0;
        for (const career of this.careers) {
          if (this.$first(career.category)?.id === category.id) {
            occurrences++;
          }
        }
        return { id: category.id, title: `${category.title} (${occurrences})` };
      });

      const locations = this.uniqueObjectId(
        this.careers
          .map(({ location }) => this.$first(location))
          .filter(Boolean)
      );

      const jobTypes = Array.from(
        new Set(this.careers.map(({ jobType }) => jobType).filter(Boolean))
      ).map((jobType) => ({ id: jobType, title: jobType }));

      return [
        {
          id: 'type',
          title: this.$t('category'),
          filters: types,
        },
        {
          id: 'location',
          title: this.$t('location'),
          filters: locations,
        },
        {
          id: 'techType',
          title: this.$t('career.mainExpertise'),
          filters: jobTypes,
        },
      ];
    },
    isActive() {
      return (
        this.active || this.$store.state.overlays.openings?.active || false
      );
    },
    /**
     * Determine which items to show based on the current selection of filters.
     */
    filteredResults() {
      const typeFilters = this.typeFilters;
      const locationFilters = this.locationFilters;
      const techFilters = this.techFilters;

      // first: filter by job type
      const byType = typeFilters.length
        ? this.careers.filter(filterByType, typeFilters)
        : this.careers;

      // then subfilter those results by location and tech type
      let subFiltered = locationFilters.length
        ? byType.filter(filterByLocation, locationFilters)
        : byType;

      subFiltered = techFilters.length
        ? subFiltered.filter(filterByTechType, techFilters)
        : subFiltered;

      return [...subFiltered];
    },
    /**
     * Get the index of the category of filters currently showing.
     * Only applicable on mobile, upto 768px.
     * Above, all filter categories will show simultaneously.
     */
    currentFilterCategory() {
      if (this.showFilters === false) {
        return false;
      }

      return this.categories.find(
        (category) => category.id === this.showFilters
      );
    },
    typeFilters() {
      return this.filters.type || [];
    },
    locationFilters() {
      return this.filters.location || [];
    },
    techFilters() {
      return this.filters.techType || [];
    },
    typeFilterButtonLabel() {
      return this.getFilterButtonLabel('Types', this.typeFilters?.length);
    },
    techFilterButtonLabel() {
      return this.getFilterButtonLabel(
        'Popular keywords',
        this.techFilters?.length
      );
    },
    locationFilterButtonLabel() {
      return this.getFilterButtonLabel(
        'Location',
        this.locationFilters?.length
      );
    },
  },
  watch: {
    filters(value) {
      localStorage.setItem('career-filters', JSON.stringify(value));
    },
    async isActive(value) {
      if (value && !this.careerCategoriesFetched) {
        await this.fetchCareerCategories();
      }

      // Get saved filters from local storage
      const filters =
        JSON.parse(localStorage.getItem('career-filters')) || DEFAULT_FILTERS;

      // Filter out any categories that are no longer available
      filters.type = filters.type.filter((type) =>
        this.careerCategories.some((category) => category.id === type)
      );

      const { categoryId } = this.$store.state.overlays.openings;

      if (categoryId) {
        filters.type = [categoryId];
      }

      this.filters = filters;
    },
  },
  mounted() {
    if (this.$route.hash === '#jobs') {
      this.$store.commit('overlays/openings', {
        active: true,
      });
    }
  },
  methods: {
    async fetchCareerCategories() {
      this.loading = true;

      const result = await this.$cms.query({
        query,
        variables: { site: this.$i18n.locale },
      });

      this.careerCategories = result?.categories || [];
      this.careerCategoriesFetched = true;
      this.loading = false;
    },
    uniqueObjectId(objArray, count = false) {
      const idSet = new Set();
      return objArray.filter(({ id }) => {
        return !idSet.has(id) && !!idSet.add(id);
      });
    },
    /**
     * On mobile, when one of the pill buttons is clicked, open exra overlay with filter checkboxes.
     */
    onFilterButtonClick(index) {
      this.showFilters = this.categories[index].id;
    },
    /**
     * Close the extra overlay of filter checkboxes again.
     */
    onViewResultsButtonClick() {
      this.showFilters = false;
    },
    /**
     * Reset all filters.
     */
    onResetButtonClick() {
      this.filters = {
        type: [],
        location: [],
        techType: [],
      };
    },
    /**
     * On bp < 768 we only show 1 filter category simultaneously.
     * In that case, only reset that category of filters, not all.
     */
    onCategoryResetButtonClick() {
      const filters = { ...this.filters };

      if (this.currentFilterCategory) {
        filters[this.currentFilterCategory.id] = [];
      }

      this.filters = filters;
    },
    getFilterButtonLabel(filterName, filterCount) {
      return `${filterName} <span class="text-primary ml-8">${filterCount}</span>`;
    },
  },
};
