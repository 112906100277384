
import { string } from 'vue-types';
import { ItemType } from './card/CareerListItem.vue';
export default {
  props: {
    heading: string(),
    buttonLabel: string(),
    openApplication: ItemType,
  },
  methods: {
    openApplyModal() {
      this.$store.commit('overlays/apply', {
        active: true,
        careerTitle: this.openApplication.title,
        greenhouseId: this.openApplication.greenhouseId,
      });
    },
  },
};
